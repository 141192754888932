.NewNameCotragent {
  width: 100%;
  margin: 10px 5px;
  padding: 10px;
  font-size: 22px;
  border: none;
  background: #0e1621ae;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: 0px 0px 5px -1px rgb(255, 255, 255);
}
.BtnBlock {
  display: flex;
  justify-content: center;
  padding: 15px 0;
}

.NewNameCotragent:focus {
  border: none;
  outline: none;
  background: #fff;
  box-shadow: 0px 0px 5px -1px red;
  color: #0e1621;
}
.NewNameBtn:hover {
  background: brown;
  border-color: brown;
}
