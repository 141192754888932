.FormGetMonthBlock {
  display: flex;
  justify-content: space-between;
}
.FormGetMonth {
  display: flex;
}
.FormGetDataUser {
}

/* .WorkerTableBlock {
  overflow-x: scroll;
} */
.WorkerTableBlockTh {
  padding: 5px 0px;
  width: 100px;
}

.WorkerTableBlockSpans {
  font-size: 16px !important;
  writing-mode: vertical-lr !important;
  transform: rotate(180deg);
  color: #fff !important;
}

.WorketDayRow {
  padding: 5px 0;
  font-size: 18px;
}
.WorkerTableBlockBtn {
  padding: 3px 10px !important;
}

.WorkerTableBlockBtn:hover {
  background: #ff4d4f;
  border-color: #ff4d4f;
}
