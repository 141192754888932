.CashDataLodain {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}
.PrintCashTable {
  display: flex;
  justify-content: flex-start;
  padding: 15px 0;
}

@media print {
  @page {
    margin: 20px 20px 0px 20px;
    font-size: 20px;
  }
}
