.ClientAvansForm {
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ClientAvansInput {
  width: 100%;
  margin: 10px 0px;
  padding-left: 10px;
  font-size: calc(4px + 12 * (100vw / 1280));
  border: none;
  background: #0e1621ae;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: 0px 0px 5px -1px rgb(255, 255, 255);
}

.ClientAvansInput:focus {
  /* border: 1px solid red; */
  border: none;
  outline: none;
  background: #fff;
  box-shadow: 0px 0px 5px -1px red;
  color: #0e1621;
}

.ClientAvansButton {
  outline: none;
  height: 5vh;
  border: 0.5px solid #008080;
  border-radius: 5px;
  background: #fff;
  color: red;
  margin-left: 5px;
  padding: 0 5px;
}

.ClientAvansButton {
  background: #0e1621ae;
  color: #fff;
  border-radius: 5px;
  border-color: #0e1621ae;
  padding: 2px 10px;
  margin: 0 5px 0 0;
  font-size: 16px !important;
  height: max-content;
  cursor: pointer;
}
.ClientAvansButton:hover {
  background: #ff4d4f;
  border-color: #ff4d4f;
}

@media print {
  .ClientAvansBtnBlock {
    display: none;
  }
}

@media (min-width: 501px) and (max-width: 1000px) {
  .ClientAvansInput {
    padding: 5px 0 5px 5px;
  }
  .ClientAvansButton {
    padding: 2px 10px;
    margin: 0 5px 0 0;
    font-size: 12px !important;
  }
}

@media (min-width: 270px) and (max-width: 500px) {
  .ClientAvansInput {
    padding: 5px 0 5px 5px;
  }
  .ClientAvansButton {
    padding: 2px 10px;
    margin: 0 5px 0 0;
    font-size: 12px !important;
  }
}
