.orderSingleHeader {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: auto;
  padding: 0 50px;
  height: calc(40vh - 50px);
}

.orderCashData {
  padding-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 20px;
}
