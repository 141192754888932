.TableFinished {
  width: 100%;
}

.ModalClientInfo {
  font-size: 18px;
  padding: 10px 0;
}

.FormAddInst {
  display: flex;
  justify-content: space-around;
}
.FormAddInstBtn {
  padding: 42px 0 0 0;
}

@media print {
  .BlockPrint {
    display: none;
  }
}
