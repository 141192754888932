.blockform {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
}

.courseInp {
  height: 32px;
  width: 200px !important;
  font-size: 12px;
  padding-left: 10px;
}

.StyleInp {
  width: 400px !important;
  padding: 10px !important;
  font-size: 20px !important;
  border-radius: 10px !important;
}

.blockformBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formBtn {
  width: 200px;
  padding: 0px 20px;
  border-radius: 10px !important;
}

.HeaderInterval {
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
}

/* contragent all css */
.ContragentAllHeader {
  display: flex;
  justify-content: space-between;
}

.BlockKalkulyation {
  display: flex;
  justify-content: space-around;
}

.ContragentAllTable {
  width: 100%;
  /*border-spacing: 5px;
  */
  background: #ffffff7a;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.ContragentAllTh {
  text-align: start !important;
}

.ContragentAllTh {
  /*width: 60px;
  s*/
  padding: 0 5px;
}

.ContragentAllTh:nth-child(8) {
  /* width: 150px; */
}
.ContragentAllBodyTr:hover {
  background-color: #adadad41 !important;
}
.ContragentAllTd {
  font-size: 18px;
  /* text-align: center; */
  padding: 10px 5px;
  margin: 5px 0;
  border-top: 2px solid #e1e3e0;
  border-bottom: 2px solid #e1e3e0;
  /* border-radius: 10px; */
  /* background: #ffffff; */
  color: black;
  text-align: start !important;
}

.contragetDelete {
  background: #ff4d4f;
  color: #fff;
  border-radius: 5px;
  border-color: #ff4d4f;
  padding: 2px 10px;
  margin: 0 5px 0 0;
  font-size: 16px !important;
  height: max-content;
  cursor: pointer;
}

/* модуль изминения данных контрагента */

.ContragentEditInp {
  width: 100%;
  margin: 10px 5px;
  padding: 10px;
  font-size: 22px;
  border: none;
  background: #0e1621ae;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: 0px 0px 5px -1px rgb(255, 255, 255);
}

.ContragentEditInp:focus {
  /* border: 1px solid red; */
  border: none;
  outline: none;
  background: #fff;
  box-shadow: 0px 0px 5px -1px red;
  color: #0e1621;
}

.contragetEditBtn {
  background: #0e1621ae;
  color: #fff;
  border-radius: 5px;
  border-color: #0e1621ae;
  padding: 2px 10px;
  margin: 0 5px 0 0;
  font-size: 16px !important;
  height: max-content;
  cursor: pointer;
}

.contragetEditBtn:hover {
  background: #ff4d4f;
  border-color: #ff4d4f;
}

/* loding block */
.ContragentLoding {
  width: 100%;
  display: flex;
  justify-content: center;
}

/*удалить все*/
.ContragentTableData {
  font-size: 20px;
  padding: 6px 0;
}

.DeleteDataContragent {
  background: #ff4d4f;
  color: #fff;
  border-radius: 5px;
  border-color: #ff4d4f;
  padding: 2px 10px;
  margin: 0 5px 0 0;
  font-size: 16px !important;
  height: max-content;
}

/* интервал данных контрагентов */
.ContragentIntervalCashData {
  display: flex;
  justify-content: space-around;
}

.ContragentIntervalP {
  font-size: 25px;
}

.ContragentIntervalTd {
  font-size: 20px;
  padding: 5px 0;
  text-align: center;
}

.ContragentIntervalTd:nth-child(1) {
  width: 50px;
}

.ContragentIntervalTd:nth-child(2) {
  width: 100px;
}

.ContragentIntervalTd:nth-child(3) {
  width: 200px;
}

.ContragentIntervalTd:nth-child(5) {
  width: 200px;
}

.ContragentIntervalTd:nth-child(6) {
  width: 200px;
}

.ContragentIntervalTd:nth-child(7) {
  width: 200px;
}

@media print {
  .ContragentIntervalCashData {
    color: #0e1621;
  }

  .ContragentIntervalTr {
    color: #0e1621;
  }
}

/* @media print {
  @page {
    color: black;
  }
} */
/**/
.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #f6f6f6;
  border-radius: 20px;
}

.table_header_row {
  /* border: 1px solid #eeeeee; */
  background: #dddddd;
}

.header_row_th {
  text-transform: uppercase;
  padding: 10px 0 10px 10px;
  text-align: start;
  position: relative;
  font-weight: 800;
}

.header_row_th:not(:nth-child(8)):before {
  position: absolute;
  top: 50%;
  inset-inline-end: 0;
  width: 1px;
  height: 20px;
  background-color: #eeeeee;
  transform: translateY(-50%);
  transition: background-color 0.2s;
  content: '';
}

.body_row {
  border-bottom: 1px solid #eeeeee;
}

.body_row:hover {
  background: #f5f5f5;
}

.body_row_td {
  font-size: 18px;
  padding: 10px 0 10px 10px;
  text-align: start !important;
}

.body_row_td:nth-child(8) {
  display: flex;
  justify-content: space-around;
}
