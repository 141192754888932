.EditWorkingContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 20px;
}

.clientAddInp {
  width: 100%;
  margin: 10px 5px;
  padding: 10px;
  font-size: 22px;
  border: none;
  background: #969595;
  color: black;
  border-radius: 5px;
}

.clientAddInp:focus {
  /* border: 1px solid red; */
  border: none;
  outline: none;
  background: #fff;
  box-shadow: 0px 0px 5px -1px red;
}

.StyleBtnAdd:hover {
  background: brown;
  border-color: brown;
}
