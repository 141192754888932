.orderTable {
  width: 100%;
  border-collapse: collapse;
}
.orderTableSingle {
  width: 100%;
  border-collapse: collapse;
  margin-top: 50px;
}

.orderTable tr th:nth-child(1) {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.orderTable tr th:nth-last-child(1) {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
