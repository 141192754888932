/* .name-table-btn {
  background: #00ff19;
  color: #fff;
  border-radius: 4px;
  border-color: #00ff19;
  padding: 5px 10px;
  margin-left: 5px;
} */

.block-name-table {
  width: 100%;
  height: 100vh;
  padding-top: 20px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 1001;
  overflow: auto;
}
.block-name-table.active {
  opacity: 1;
  pointer-events: all;
}
.block-name-table-content {
  padding: 20px;
  background-color: white;
  width: 90vw;
  border-radius: 5px;
  transition: 0.2s all;
  z-index: 1002;
}
.exit__modal-card {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.exit__modal-card-btn {
  background: #ff4d4f;
  color: #fff;
  border-radius: 5px;
  border-color: #ff4d4f;
  padding: 2px 10px;
  margin: 0 5px 0 0;
  font-size: 16px !important;
  height: max-content;
}

/* @media print {
  @page {
    color: black;
  }
} */
