.AccoutingUserData {
  padding-top: 10px;
  padding-bottom: 10px;
}

.AccoutingCashInp {
  width: 100%;
  margin: 3px 0px;
  padding: 2px;
  font-size: 14px;
  border: none;
  background: #969595;
  color: black;
  border-radius: 5px;
}

.AccoutingCashInp:focus {
  /* border: 1px solid red; */
  border: none;
  outline: none;
  background: #fff;
  box-shadow: 0px 0px 5px -1px red;
}
.AccoutingFormBlock {
  height: 600px;
  overflow-y: scroll;
}
.BtnAddAccounting:hover {
  background: firebrick;
  border-color: firebrick;
}
