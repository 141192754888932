.BlockBtnPrint {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}

.BlockInfoUser {
  margin: 10px 0;
}
