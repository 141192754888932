/* Russets */
.Client {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Russetclients {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Russets {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Russets_h1 {
  display: flex;
  justify-content: center;
}
.Russets_h1 h1 {
  font-size: 32px;
  color: #008075;
}
/* end Russets */
.UserArxivList {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.UserArxivtList__h1 {
  color: #008075;
  text-transform: uppercase;
  font-size: calc(12px + (10 + 10 * 0.7) * ((100vw - 320px) / 1280));
  letter-spacing: 2px;
  margin-left: 5px !important;
  font-weight: 800;
}
/* .H1 {
  color: #008075;
  text-transform: uppercase;
  font-size: calc(12px + (10 + 10 * 0.7) * ((100vw - 320px) / 1280));
  letter-spacing: 5px;
  font-weight: 800;
  margin-left: 5px !important;
} */
.PokupkaBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* OborotMniBlock */
.OborotMniBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* OborotMniBlock */

.BlockOperation {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

.HeaderContragent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
}
.ContragentBlockBtn {
  display: flex;
  align-items: center;
}
.BlockRasxodHeader {
  display: flex;
  justify-content: space-between;
}

/* Client single style */
.ClientSingleHeader {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
/* End Client single style */
