.WorkersArchiveTableBlock {
  width: 100%;
}
.WorkerArchiveData {
  font-size: 22px;
  text-align: center;
}
.WorkerArchiveInterval:hover {
  background: black;
  color: aliceblue;
}
.WorkersArchivePrint {
  width: 100%;
  padding: 15px 0;
  display: flex;
  justify-content: flex-end;
}
/* arxiv */
.WorkersIntervalArxiv {
  display: flex;
  justify-content: space-between;
}
.IntervalArxivHeader {
  display: flex;
  justify-content: flex-end;
}
