.WorkPrizeInp {
  font-size: 20px !important;
  border-radius: 5px !important;
}
.WorkPrizeBlockBtn {
  width: 100%;
  display: flex;
  justify-content: center;
}
.WorkPrizeBtn {
  padding: 2px 20px !important;
}
.WorkPrizeBtn:hover {
  background: brown;
  border-color: brown;
}
