.ClientProductForm {
  display: flex;
  flex-direction: column;
}
.ClientProductTextarea {
  width: 500px;
  font-size: calc(4px + 12 * (100vw / 1280));
  border-radius: 5px;
  border: 0.5px solid #008080;
  background: #fff;
  color: #008080;
  outline: none;
}
.ClientProduct__btn {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: flex-end;
  padding-top: 5px;
}

.ClientProductButton {
  background: #0e1621ae;
  color: #fff;
  border-radius: 5px;
  border-color: #0e1621ae;
  padding: 2px 10px;
  margin: 0 5px 0 0;
  font-size: 16px !important;
  height: max-content;
  cursor: pointer;
}
.ClientProductButton:hover {
  background: #ff4d4f;
  border-color: #ff4d4f;
}

.ClientProductTable {
  width: 100%;
  border: 1px solid rgb(255, 255, 255);
  margin-top: 20px;
}
.ClientProductTable tr th {
  font-family: "Roboto", sans-serif;
  border-bottom: 1px solid rgb(255, 255, 255);
  background-color: #008080;
  text-align: center;
  color: #ffffff;
  font-size: calc(2px + 14 * (100vw / 1280)) !important;
}

.ClientPropductText {
  font-size: calc(4px + 12 * (100vw / 1280));
  color: #008080;
}

.ClientProductDellButton {
  border: none;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 5px;
  color: red;
  font-size: 20px;
}
.ClientProductDell {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 50px;
  padding-top: 10px;
}
@media print {
  .ClientProductDellButton {
    display: none;
  }
}
