.block__btn {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.CardContainer {
  /* display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 20px;
  padding-top: 20px; */
}
.CardContainerData:hover {
  background: #0080807f;
  color: #fff !important;
  /* display: flex; */
  /* flex-direction: column;
  padding: 20px; */
}
.CardBlockBtn {
  display: flex;
  justify-content: space-around;
}

/* Pin block */
.CaverBlock {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.CaverInp {
  border-radius: 5px;
  border: 1px solid rgb(177, 177, 177);
  background: #fff;
  font-size: 22px;
  color: #008080;
  outline: none;
  text-align: center;
  margin-top: 10px;
}
.CaverInp:focus {
  background: #fff;
  border-color: #008080;
}

.LoadingBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
