.IntervalFormBlock {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
}
.IntervalForm {
  display: flex;
}

/* interval table */
.RasxodIntervalTr:hover {
  color: aliceblue;
  background: black !important;
}
.RasxodIntervalTd {
  font-size: 18px;
  padding: 5px 0;
}
.RasxodIntervalTd:nth-child(1) {
  text-align: center;
}
.RasxodIntervalTd:nth-child(2) {
  text-align: center;
}
.RasxodIntervalTd:nth-child(4) {
  text-align: center;
  font-size: 22px;
}
.RasxodIntervalTd:nth-child(4):hover {
  background: black;
}
