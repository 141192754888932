@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;700;900&display=swap');
/* @import url("https://fonts.googleapis.com/css2?family=Koulen&family=Russo+One&display=swap"); */

:root {
  /* font-size: 1.2em; */
  --ant-primary-color: #1890ff;
  --ant-primary-color-hover: #40a9ff;
  --ant-primary-color-active: #096dd9;
  --ant-primary-color-outline: rgba(24, 144, 255, 0.2);
  --ant-primary-1: #fdfdfd;
  --ant-primary-2: #bae7ff;
  --ant-primary-3: #91d5ff;
  --ant-primary-4: #69c0ff;
  --ant-primary-5: #40a9ff;
  --ant-primary-6: #1890ff;
  --ant-primary-7: #096dd9;
  --ant-primary-color-deprecated-l-35: #cbe6ff;
  --ant-primary-color-deprecated-l-20: #7ec1ff;
  --ant-primary-color-deprecated-t-20: #46a6ff;
  --ant-primary-color-deprecated-t-50: #8cc8ff;
  --ant-primary-color-deprecated-f-12: rgba(24, 144, 255, 0.12);
  --ant-primary-color-active-deprecated-f-30: rgba(230, 247, 255, 0.3);
  --ant-primary-color-active-deprecated-d-02: #dcf4ff;
  --ant-success-color: #52c41a;
  --ant-success-color-hover: #73d13d;
  --ant-success-color-active: #389e0d;
  --ant-success-color-outline: rgba(82, 196, 26, 0.2);
  --ant-success-color-deprecated-bg: #f6ffed;
  --ant-success-color-deprecated-border: #b7eb8f;
  --ant-error-color: #ff4d4f;
  --ant-error-color-hover: #ff7875;
  --ant-error-color-active: #d9363e;
  --ant-error-color-outline: rgba(255, 77, 79, 0.2);
  --ant-error-color-deprecated-bg: #fff2f0;
  --ant-error-color-deprecated-border: #ffccc7;
  --ant-warning-color: #faad14;
  --ant-warning-color-hover: #ffc53d;
  --ant-warning-color-active: #d48806;
  --ant-warning-color-outline: rgba(250, 173, 20, 0.2);
  --ant-warning-color-deprecated-bg: #fffbe6;
  --ant-warning-color-deprecated-border: #ffe58f;
  --ant-info-color: #1890ff;
  --ant-info-color-deprecated-bg: #e6f7ff;
  --ant-info-color-deprecated-border: #91d5ff;
  --ant-info-color-black: #000000ff;
}

h1,
h2,
h3 {
  padding: 0 !important;
  margin: 0 !important;
}
/* a {
  color: white !important;
} */
* {
  /* font-family: "Roboto", sans-serif; */
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  /* -webkit-touch-callout: none; iOS Safari */
  /* -webkit-user-select: none; Chrome/Safari/Opera */
  /* -khtml-user-select: none; Konqueror */
  /* -moz-user-select: none; Firefox */
  /* -ms-user-select: none; Internet Explorer/Edge */
  /* user-select: none; */
}

.swal-text {
  font-size: 25px;
  text-align: center;
}

/* !scroll */
::-webkit-scrollbar-track {
  background-color: #ffffff; /*Цвет скролла*/
}

::-webkit-scrollbar-thumb {
  border-radius: 5px; /*Закругление ползунка*/
  background-color: #ff000096; /*Цвет ползунка*/
}

::-webkit-scrollbar {
  width: 8px; /*Толщина скролла*/
  height: 15px;
}

.ant-layout {
  background: none !important;
}

/* !scroll */
/* .global__app {
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'Sidebar Header Header'
    'Sidebar Content Content';
} */

.global__app-menu {
  grid-area: Sidebar;
  /* background: #001529 !important; */
  background: #17212b;
}
/* 
.global__app-content {
  grid-area: Content;
  padding: 0px 10px 0px 10px;
  overflow-y: auto;
} */

/*! antd menu */
.ant-table {
  border-radius: 10px !important;
}

.ant-table-wrapper {
  width: 100%;
}

.ant-table-thead .ant-table-cell {
  /* color: #008080; */
  /* font-size: calc(2px + 10 * (100vw / 1280)); */
  /* text-align: center; */
  padding: 3px !important;
  font-weight: 700;
}

/*.ant-table-thead .ant-table-cell:nth-of-type() {*/
/*    writing-mode: tb-rl;*/
/*    transform: rotate(180deg);*/
/*    font-size: 40px;*/
/*}*/

.ant-table-row .ant-table-cell {
  padding: 5px !important;
  /* color: var(--ant-info-color-black); */
  /* text-align: center !important; */
  /* font-size: calc(2px + (10 + 10 * 0.7) * ((100vw - 320px) / 1280)); */
  /* font-weight: 700 !important; */
}

/* .ant-table-row:nth-child(even) .ant-table-cell {
  background: #f4f4f4 !important;
} */
.ant-table-row:hover .ant-table-cell {
  background: var(--ant-warning-color) !important;
  color: var(--ant-primary-1) !important;
}

.ant-table-cell .column-money {
  width: 100px !important;
}

.ant-table-cell .column-name {
  height: 60px;
}

/* .ant-table-tbody > tr > td {
  background: var(--ant-primary-1);
  color: var(--ant-info-color-black);
  padding: 0 !important;
} */

.ant-dropdown-trigger {
  width: 50px;
  padding: 0 0 0 15px !important;
}

.cash__column-color {
  background: #27e0746b !important;
  color: var(--ant-info-color-black) !important;
}

.raschot__column-color {
  background: #ffa9405e !important;
  color: var(--ant-info-color-black) !important;
}

/* .ostatok__column-color {
  background: red !important;
  color: #fff !important;
} */
.rasxod__column-color {
  background: rgb(221, 221, 221) !important;
}

.ostatok__column-color {
  background: rgba(255, 0, 0, 0.24) !important;
  color: var(--ant-info-color-black) !important;
}

.UsersFontSize {
  font-size: 14px !important;
}

/* .status__column-color {
  background: #aac4f5 !important;
  color: #fff !important;
} */
/*  */
/* .text-adeptiv {
  white-space: normal !important;
  text-overflow: ellipsis !important;
} */

/* hover */
.hover__color {
  opacity: 0 !important;
  transition: 0.3s;
}

.active {
  opacity: 1 !important;
  transition: 0.3s;
}

/* hover */

/*  */
.clientt__table-key {
  /* font-size: calc(3px + 16 * (100vw / 2650)) !important; */
}

.class__none {
  display: none !important;
}

.client__table-date {
  /* font-size: calc(6px + 16 * (100vw / 2650)) !important; */
}

/*  */
.MuiPaper-root {
  margin-top: 10px !important;
}

.ant-table-cell .editable-cell-value-wrap {
  height: 34px !important;
  cursor: pointer;
  font-size: 24px;
}

.TableUsers {
  width: 100% !important;
  overflow: scroll !important;
}

.selected {
  width: 50px;
  background: #008080;
}

.button {
  width: 50px;
}

/* select items */
.ant-select-item-option-content {
  font-size: 20px;
}

/* LoginForm */

.Login {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #007878;
}

.LoginButton {
  width: 150px !important;
}

/* Login css */
.loginForm {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  background: #008080;
}

.loginForm h1 {
  color: var(--ant-primary-1);
}

.loginForm h2 {
  margin: 0 0 10px 0;
  color: var(--ant-primary-1);
}

.loginFormInput {
  width: 270px;
  padding: 4px 5px;
  margin: 10px;
  border-radius: 8px;
  border: 1px solid rgb(138, 136, 136);
  outline: none;
}

.loginFormInput:focus::placeholder {
  color: transparent;
  border-color: #1890ff;
}

.qr-cod {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0px 10px 10px 0px;
}

.qr-cod a {
  color: var(--ant-primary-1);
}

.loginForm .Btn {
  margin-top: 20px;
  width: 150px;
  border-radius: 10px !important;
  border-color: var(--ant-primary-1);
  background: var(--ant-primary-1);
  cursor: pointer;
  color: #008080 !important;
}

.loginForm .Btn:hover {
  color: #1890ff;
  transition: 0.7s;
}

/* Card */
.site-card-border-less-wrapper {
  padding: 30px;
  background: #ececec;
}

/*  */

/* login css */

.ant-table-cell.day {
  padding: 3px !important;
  writing-mode: vertical-lr !important;
  transform: rotate(180deg);
  align-items: center !important;
  text-align: center !important;
  font-size: calc(4px + (6 + 6 * 0.7) * ((100vw - 320px) / 1280));
}

.text-adeptiv {
  white-space: normal !important;
  text-overflow: ellipsis !important;
}

.day {
  padding: 3px !important;
  writing-mode: vertical-lr !important;
  transform: rotate(220deg);
  align-items: center !important;
  text-align: center !important;
  font-size: calc(4px + (6 + 6 * 0.7) * ((100vw - 320px) / 1280));
}

.TableTag {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.serTeg {
  display: flex;
}

.EditableCellValueWrap {
  display: flex;
}

.RoeGlass {
  height: 40px !important;
}

.ant-table-cell.OtherComment {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ant-modal-content {
  width: 100%;
}

.DateWidth {
  font-size: calc(8px + 12 * (100vw / 2650)) !important;
}

.OborotTAble {
  font-size: calc(8px + (6 + 6 * 0.7) * ((100vw - 320px) / 1280)) !important;
  text-align: center;
}

.OborotTableFoyda {
  font-size: calc(8px + 16 * (100vw / 1280)) !important;
  color: #00ff15 !important;
  text-align: center !important;
}

/*  */

@media (min-width: 700px) and (max-width: 1260px) {
  .ant-modal-content {
    width: 100% !important;
  }

  .OborotTAble {
    font-size: calc(0px + 16 * (100vw / 1280));
  }

  .OborotTableFoyda {
    font-size: calc(0px + 16 * (100vw / 1280));
    color: #910000 !important;
  }

  .ant-table-cell
    .ColumnPrintWidth
    .ant-table-cell-fix-right
    .ant-table-cell-fix-right-first {
    width: 100px !important;
  }

  .ant-table-cell.ColumnPrintWidth {
    line-height: 20px;
    padding: 10px !important;
  }
}

@media print {
  @page {
    size: auto;
    margin: 0px;
  }

  .ant-table-wrapper {
    width: 100vmax;
  }

  .WorkerPrint {
    width: 100%;
  }

  .ColumnPrintBlock {
    opacity: 0;
  }

  .ant-table-cell.day {
    writing-mode: none !important;
    transform: rotate(0);
  }

  .PtintUser {
    display: block;
  }

  .ModalBlockText {
    border: 2px solid green;
    width: 100vmax;
    margin-top: 20px;
  }

  .ModalUserTH {
    border: 1px solid rgb(49, 49, 49);
    text-align: center;
    color: #196f3d;
    font-size: 16px;
  }

  .ModalUserInfo {
    border: 1px solid rgb(49, 49, 49);
    text-align: center;
    color: #008080;
  }
}

/* cssTransition личные карточки*/
.note-exit-active {
  animation: note 500ms ease-out forwards;
}

.note-enter-active {
  animation: note 500ms ease-in alternate-reverse;
}

@keyframes note {
  0% {
    transform: translateX(0);
  }
  30% {
    opacity: 7;
    transform: translateX(30px);
  }
  100% {
    opacity: 0;
    transform: translateX(-200px);
  }
}
