.FormBlock {
  display: flex;
  justify-content: space-between;
}
.Form {
  display: flex;
  justify-content: flex-end;
}
.cashFetchInfo {
  display: flex;
  justify-content: flex-start;
}
