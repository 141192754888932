.ful__modal-btn {
  background: #ff4d4f;
  color: #fff;
  border-radius: 5px;
  border-color: #ff4d4f;
  padding: 2px 10px;
  margin: 0 5px 0 0;
  font-size: 16px !important;
  cursor: pointer;
}
/* telegrem button color #3476ab */

.ful-open-modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 0, 0, 0);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s;
  z-index: 1001;
  overflow: hidden;
  overflow-y: scroll;
}
.ful-open-modal.actives {
  top: 0;
  left: 0;
  opacity: 1;
  pointer-events: all;
}
.ful-modal-content {
  width: 100vw;
  height: 100vh;
  padding: 20px;
  transition: 0.4s all;
  z-index: 1002;
  overflow-y: scroll;
}
.ful-modal-close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media print {
  @page {
    size: a4 album;
    margin: 20px 20px 0px 20px;
    font-size: 18px;
  }
  .ful-modal-content {
    overflow: hidden;
  }
}
