@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

.MadalSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.MadalTitleH1 {
  font-size: 30px;
  color: #008080;
  margin-bottom: 50px;
}
.BlockBtbModal {
  width: 100%;
  padding: 0;
  margin: 10px 0 0 0;
  display: flex;
}
/* Block Client info modal */
.ModalBlockText {
  border: 1px solid rgb(255, 255, 255);
  width: 100%;
}
.ModalClientTH {
  font-family: "Roboto", sans-serif;
  border-bottom: 1px solid rgb(255, 255, 255);
  background-color: #008080;
  text-align: center;
  color: #ffffff;
  font-size: calc(2px + 14 * (100vw / 1280));
}
.ModalClientInfo {
  font-size: calc(0px + 16 * (100vw / 1280));
  border-bottom: 1px solid rgb(255, 255, 255);
  text-align: center;
  color: #008080;
}
/* AvansPlusBlock */
.AvansPlusBlock {
  width: 100%;
  margin: 15px 0px 15px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}
.AvansPlusH1 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #008080;
}

.InputNumberWidth {
  width: 250px !important;
}
/* end AvansPlusBlock */
.PrintTextClient {
  display: block;
  width: 100%;
  padding: 50px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}
.PrintTextPechat {
  display: inline-block;
  width: 100%;
  padding-left: 50px;
  font-size: 12px;
  text-align: left;
}

@media print {
  .BlockFormComm {
    display: none;
  }
  .ModalClientTH {
    font-size: 1.2em !important;
  }
  .ModalClientInfo {
    font-size: 20px !important;
  }
  .PrintText {
    font-size: 1em;
  }
  .BlockBtbModal {
    display: none;
  }
}
