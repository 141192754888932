.PriceCashInp {
  width: 100%;
  margin: 10px 5px;
  padding: 10px;
  font-size: 22px;
  border: none;
  background: #969595;
  color: black;
  border-radius: 5px;
}

.PriceCashInp:focus {
  /* border: 1px solid red; */
  border: none;
  outline: none;
  background: #fff;
  box-shadow: 0px 0px 5px -1px red;
}

.PriceCashBtn:hover {
  transition: 0.4s;
  /* background: #969595; */
  background: #ff4d4f;
  border-color: #ff4d4f;
  box-shadow: 0px 0px 2px 2px #969595;
}
.WorcPriceHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.WorkerPriceTdata {
  padding: 20px 0;
  border-bottom: 1px solid #d9d9d9;
  font-size: 18px;
  color: black;
  text-align: center;
}
