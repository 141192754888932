:placeholder-shown {
  color: #fff;
}

.BlockAddUsers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
.StyleInpCheck {
  width: 25px;
  height: 25px;
  margin-left: 15px;
  vertical-align: middle;
}
.StyleInpSel {
  width: 100%;
  margin: 10px 5px;
  padding: 10px;
  font-size: 22px;
  border: none;
  background: #0e1621ae;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: 0px 0px 5px -1px rgb(255, 255, 255);
}

.StyleInpSel:focus {
  /* border: 1px solid red; */
  border: none;
  outline: none;
  background: #fff;
  box-shadow: 0px 0px 5px -1px red;
  color: #0e1621;
}

.StyleBtn {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.StyleBtnAdd:hover {
  background: brown;
  border-color: brown;
}
