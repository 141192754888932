.DiaryLoding {
  width: 100%;
  margin: 0 auto;
  padding-left: 50%;
}

.DiaryTableBlock {
  overflow: auto;
  height: 50vh;
  box-sizing: border-box;
  padding-top: 30px;
  margin-top: 15px;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.DiaryTableBody {
  display: flex;
}
