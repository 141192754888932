.statisticCard{
    width: 45%;
    height: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-image: linear-gradient(to right, #6db2f8, #a9d3f8);
}
.statisticCard:hover {
    /*border-color: transparent;*/
    box-shadow: 0 1px 2px -2px rgba(0,0,0,.16), 0 3px 6px 0 rgba(0,0,0,.12), 0 5px 12px 4px rgba(0,0,0,.09);
}
.statisticCardTitle{
    margin-bottom: 4px;
    color: #fff;
    font-size: 16px;
}
.statisticCardData{
    color: #001529;
    font-variant: tabular-nums;
    line-height: 1.5715;
    font-feature-settings: "tnum";
    font-size: 24px;
}