/* .BlockCashInfo {
  display: flex;
  justify-content: space-around;
}*/
.CashInfo {
  width: 100%;
  font-size: calc(6px + (10 + 10 * 0.7) * ((100vw - 320px) / 1280));
  text-align: left;
}
.CashInfoSpan {
  font-size: calc(6px + (10 + 10 * 0.7) * ((100vw - 320px) / 1280));
  color: red;
  padding-left: 5px;
}
/*TableSumaryData*/

/* end ableSumaryData*/
.LoadingBlock {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .CashInfo {
    color: #008080;
  }
  .CashInfoSpan {
    color: red;
    padding-left: 5px;
    line-height: 5px !important;
  }
}
