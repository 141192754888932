.currensy-btn {
  padding: 3px 10px !important;
  font-size: 20px;
  color: #fff;
}
.currency-madal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  right: -100%;
  transition: 0.7s;
  z-index: 1001;
  display: flex;
  justify-content: flex-end;
}
.currency-madal.active {
  right: 0%;
  background: rgba(255, 255, 255, 0);
}
.currency-container {
  width: 40vw;
  background: #fff;
}
.currency-madal-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(154, 140, 140, 0.478) !important;
  padding: 5px 0;
}
.currency-madal-h3 {
  padding-left: 10px !important;
}
.currency-madal-h3 span {
  color: #ff4d4f !important;
}
.currensy-btn-exit {
  padding: 3px 10px !important;
  background-color: #ff4d4f;
  color: #fff;
  margin-right: 10px;
}
.currency-madal-content {
  height: 94%;
  overflow-y: auto;
}

.corrensy-table-name {
  font-size: 14px;
  padding-left: 10px;
}
.corrensy-table-many {
  font-size: 16px;
  color: #ff4d4f;
}
.corrensy-table-many-span {
  color: #ff4d4f;
}
