.WorkHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 5px 0 0;
}
.WorkIntervalLoading {
  width: 100%;
  display: flex;
  justify-content: center;
}
.WorkBlockTable {
  display: grid;
  grid-template-columns: 55% 45%;
  grid-template-rows: 1fr;
  gap: 20px;
}

.WorkIntervalData {
  font-size: 17px;
}
