.edit__modal-btn {
  background: #ff4d4f;
  color: #fff;
  border-radius: 5px;
  border-color: #ff4d4f;
  padding: 2px 10px;
  margin: 0 5px 0 0;
  font-size: 16px !important;
  cursor: pointer;
}
/* telegrem button color #3476ab */

.edit-open-modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 1001;
  overflow: hidden;
  overflow-y: scroll;
}
.edit-open-modal.active {
  opacity: 1;
  pointer-events: all;
}
.edit-modal-content {
  padding: 20px;
  border-radius: 10px;
  transition: 0.4s all;
  z-index: 1002;
}

@media (min-width: 501px) and (max-width: 1000px) {
  .edit__modal-btn {
    width: 100%;
    padding: 2px 0;
    margin: 0 5px 5px 0;
    font-size: 12px !important;
    cursor: pointer;
  }
}

@media (min-width: 270px) and (max-width: 500px) {
  .edit__modal-btn {
    width: 100%;
    padding: 2px 0;
    margin: 0 5px 5px 0;
    font-size: 10px !important;
    cursor: pointer;
  }
}
