.qushish__modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: -100%;
  background: rgba(255, 255, 255, 0);
  pointer-events: none;
  transition: 0.5s;
  z-index: 1001;
  display: flex;
  justify-content: flex-end;
}
.qushish__modal.active {
  pointer-events: all;
  right: 0px;
}
.qushish__modal-content {
  width: 50vw;
  height: 100vh;
  padding: 20px;
  transition: 0.4s all;
  z-index: 1002;
}

.cliet__add-btn {
  padding: 5px;
  margin: 5px 0 0 5px;
}
.client__add-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
.client__add-header-title {
  display: flex;
  align-items: center;
}
.client__add-header-title span {
  margin: 0 5px 0 5px;
}
.clear__new-client:hover {
  background: brown;
  border-color: brown;
}
.add__new-client:hover {
  background: blue;
  border-color: blue;
}

/*************** Стиль формы ***********/

.client-add-inp {
  width: 100%;
  margin: 10px 5px;
  padding: 10px;
  font-size: 22px;
  border: none;
  background: #969595;
  color: black;
  border-radius: 5px;
}

.client-add-inp:focus {
  /* border: 1px solid red; */
  border: none;
  outline: none;
  background: #fff;
  box-shadow: 0px 0px 5px -1px red;
}
