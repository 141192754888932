.BlocfFormAdd {
  margin: 10px;
  display: flex;
  flex-direction: column;
}
.NameTable {
  width: 350px;
  padding: 5px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #008080;
  margin: 0 0 15px 0;
  font-size: 18px;
  color: brown;
}
.DebtTable {
  width: 350px;
  padding: 5px;
  margin: 0 0 15px 0;
  border-radius: 5px;
  outline: none;
  border: 1px solid #008080;
  font-size: 18px;
  color: brown;
}

.AddTable {
  width: 350px;
  background: #fff;
  outline: none;
  border: 1px solid #ff4d4f;
  background: #ff4d4f;
  color: #fff;
  padding: 5px 15px;
  font-size: 18px;
  border-radius: 5px;
}
.DebtCheckLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.DebtCheck {
  width: 30px;
  height: 30px;
}
