/*      */
.LodingBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.WorkerModalBlock {
  padding: 10px 0;
}
.WorkerTable {
  width: 100%;
}
.WorkerTableTh {
  /* background-color: #8c8c8c; */
  background-color: #bfbfbf;
  color: #fff;
  font-size: 20px;
}
.WorkerTableTh th {
  padding: 5px 0;
}
.WorkerTableTd {
  text-align: center;
  border-bottom: 1px solid #d9d9d9;
}
.WorkerTableTd:hover {
  background: #4a4949;
  color: #fff !important;
  /* transform: translate(-10px, -10px) scale(1); */
  /* transition: 0.3s; */
}
.WorkerTableTd:hover.WorkerTableTdata {
  color: #fff !important;
}
.WorkerTableTdata {
  padding: 5px 0;
  border-bottom: 1px solid #d9d9d9;
  font-size: 18px;
  color: black;
}
.WorkerTableTd td:nth-child(1) {
  /* border-bottom: 1px solid #d9d9d9; */
  color: #ff4d4f;
}
.WorkerTableTButton {
  display: flex;
  justify-content: space-around;
  padding: 15px 0;
}
.Workerbutton {
  background: #ff4d4f;
  color: #fff;
  border-radius: 5px;
  border-color: #ff4d4f;
  padding: 2px 10px;
  margin: 0 5px 0 0;
  font-size: 16px !important;
  height: max-content;
}
