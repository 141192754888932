p {
  padding: 0;
  margin: 0;
}
.HeaderBlock {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center !important;
}
.HeaderBlockUser {
  padding: 10px 0 0 10px;
  color: #fff;
  font-size: 22px;
}
.HeaderBlockBtn {
  width: max-content;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.OborotHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}
.FormOborotForm {
  display: flex;
}
