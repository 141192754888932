* {
  box-sizing: border-box;
}
.ClientBlockLoading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ClientBlockAvans {
  width: 100%;
  border: 1px solid rgb(255, 255, 255);
  margin-top: 20px;
}
.ClientBlockAvans tr th {
  font-family: "Roboto", sans-serif;
  border-bottom: 1px solid rgb(255, 255, 255);
  background-color: #008080;
  text-align: center;
  color: #ffffff;
  font-size: calc(2px + 14 * (100vw / 1280)) !important;
}
.ClientBlockAvans tr th:nth-child(1) {
  width: 150px !important;
}
.ClientBlockAvans tr td {
  font-size: calc(0px + 12 * (100vw / 1280));
  border-bottom: 1px solid rgb(255, 255, 255);
  text-align: center;
  color: #008080;
}

.ClientAvansText {
  color: red;
  padding-left: 10px;
}

.ClientAvansBtnBlock {
  text-align: center;
}

.ClientAvansBtn {
  border: none;
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 5px;
  color: red;
  font-size: 20px;
  padding: 0 !important;
}

@media print {
  .ClientAvansBtnBlock {
    display: none;
  }
}
