.block__menu {
  position: relative;
  display: flex;
  height: 100%;
  background: #17212b;
}

.open__menu {
  position: absolute;
  top: 10px;
  right: 30%;
  font-size: 25px;
  color: #fff;
}
.ant-menu {
  background: none;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  background: #0e1621;
  border-radius: 0;
  box-shadow: none;
}
.menu__item-link {
  font-size: 18px;
}
